import type { LayoutProps } from '../../types';

import * as React from 'react';
import { DefaultNestedLayout as NestedLayoutComponent } from '../DefaultNestedLayout';

/**
 * Extend the page view component with this component through "Component.getLayout".
 * This component is used as higher order component within _app.tsx.
 * https://nextjs.org/docs/basic-features/layouts
 * @param props LayoutProps
 * @constructor
 */
export function DefaultLayout(props: LayoutProps) {
  const { children, pageContext } = props;
  return <NestedLayoutComponent pageContext={pageContext}>{children}</NestedLayoutComponent>;
}
